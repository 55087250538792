import React from "react";
import useTestimonialsData from "@staticQueries/TestimonialsQuery";
import { TestimonialCard, Carousel } from "@molecules";
import { Container } from "@atoms";

const TestimonialCarousel = ({ testimonials }) => {
  const defaults = useTestimonialsData();
  const cards = testimonials?.length ? testimonials : defaults;
  return (
    <Container variant="sm" className="relative z-10">
      {cards?.length > 1 ? (
        <Carousel className="gap-5">
          {cards?.map((c, i) => (
            <TestimonialCard
              key={c.uid}
              color={i % 2 === 0 ? "teal" : "purple"}
              {...c}
            />
          ))}
        </Carousel>
      ) : (
        <TestimonialCard color="teal" {...cards[0]} />
      )}
    </Container>
  );
};

export default TestimonialCarousel;
