import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query TestimonialsQuery {
    craft {
      entries(section: "testimonials", limit: 3) {
        ... on Craft_testimonials_default_Entry {
          uid
          quote: descriptor0
          name: heading0
          title: heading1
          image: image0 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const testimonials = entries?.map(ent => {
    return {
      ...ent,
      image: resolveImage(ent.image),
    };
  });
  return testimonials;
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
